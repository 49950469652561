import Link from "next/link";
import Image from "next/image";
import Logo from "/public/images/logo_shape.webp";

export const Footer = () => {
  return (
    <div className="w-full max-w-7xl px-4 pt-10 pb-10 gap-4 flex flex-col">
      <Link href={"/"} passHref>
        <a className="flex flex-row gap-1 justify-start items-center">
          <span className="sr-only">Mirror Pass</span>
          <div className="h-10 w-12  flex relative justify-center items-center">
            <Image src={Logo} alt={""} layout={"fill"} />
          </div>
          <h4 className="text-xl text-white font-bold">Mirrorpass</h4>
        </a>
      </Link>
      <ul className="grid grid-cols-2 sm:flex sm:flex-row gap-2">
        <li>
          <a
            href={"https://discord.gg/t6HTEMMJhG"}
            target={"_blank"}
            rel={"noreferrer"}
            className="font-medium text-gray-400 hover:text-white cursor-pointer"
          >
            Discord
          </a>
        </li>
        <li>
          <a
            href={"https://twitter.com/mirrorpass_xyz"}
            target={"_blank"}
            rel={"noreferrer"}
            className="font-medium text-gray-400 hover:text-white cursor-pointer"
          >
            Twitter
          </a>
        </li>
        <li>
          <a
            href={"https://opensea.io/collection/mirror-passes"}
            target={"_blank"}
            rel={"noreferrer"}
            className="font-medium text-gray-400 hover:text-white cursor-pointer"
          >
            Opensea
          </a>
        </li>
      </ul>
    </div>
  );
};
