import Head from 'next/head'
import React, { ReactNode } from 'react'
import { Navigation } from './Navigation'
import { Toaster } from 'react-hot-toast'
import { Footer } from './Footer'
import { useRouter } from 'next/router'

interface IProps {
    children: ReactNode
    title?: string
}

const Layout: React.FunctionComponent<IProps> = ({ children, title = 'Mirror Pass' }) => {
    const router = useRouter()
    const isHomepage = router.pathname === '/'

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name='viewport' content='width=device-width, initial-scale=1' />
                <link rel='icon' href='/favicon.ico?v=10' />
                <meta property='og:url' content={'app.mirrorpass.xyx'} key='ogurl' />
                <meta
                    property='og:image'
                    content={
                        'https://lh3.googleusercontent.com/8rIoklY6tY5IB8baUZgJFAvfKBN7Px8A11pcV_0s9mXzrYeXvaFoax3sVkAzqz0KwbaEufleJxy_tRK1CVwAOhsdq2dj0Ixq6Ts0rA=w1400-k'
                    }
                    key='ogimage'
                />
                <meta property='og:site_name' content={'Mirror Pass'} key='ogsitename' />
                <meta property='og:title' content={'App'} key='ogtitle' />
                <meta
                    property='og:description'
                    content={
                        'Bridging the gap between the whales and the regular person by tearing down arbitrary walls for a small mint price'
                    }
                    key='ogdesc'
                />
                <meta name='twitter:card' content='summary' key='twcard' />
                <meta name='twitter:creator' content={'mirrorpass_xyz'} key='twhandle' />
            </Head>
            <div
                className='w-screen min-h-screen flex flex-col justify-start items-center overflow-y-auto overflow-x-hidden '>
                <Navigation />
                <main className='w-full flex flex-col justify-center items-center ml-4 mr-4 md:ml-0 md:mr-0'>
                    {/** @ts-ignore*/}
                    {children}
                </main>
                {isHomepage ? (
                    <footer
                        className='w-full border-t-[1px] border-[#1E1E2C] mt-8 flex flex-col items-center justify-center'>
                        <Footer />
                    </footer>
                ) : null}
            </div>
            <Toaster />
        </>
    )
}

export default Layout
